import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import { appSettings } from "@careerinsight/applib-react";

if (process.env.NODE_ENV === "development") {
  appSettings.surveyApi = "https://survey.demo.careerinsight.co.uk/v1";
  appSettings.showAnswers = true;
}

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(<App />);
