import { EmailDeliveryStatus, RespondentDefinition } from "@careerinsight/applib-common/entity";

/**
 * Should we print DEBUG statements to the console?
 */
const p = typeof process === "undefined" ? { env: { DEBUG: "TRUE" } } : process;
const DEBUG: boolean = !(p.env.DEBUG && p.env.DEBUG.toLowerCase() === "false");

export class CommonUtil {
  /**
   * Write a debug log statement. Disable this feature by setting the environment variable DEBUG=FALSE
   */
  public static debug(...args: any[]) {
    let str = "";

    if (DEBUG) {
      for (const a of args) {
        if (typeof a === "string") {
          str += a + " ";
        } else {
          if (str) console.log(str);
          str = "";
          console.log(a);
        }
      }
      if (str) console.log(str);
    }
  }

  /**
   * Given an array of objects, this function will remove all duplicate objects based on the value of a specified key
   */
  public static removeDuplicatesByKey(arr: any[], key: string) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  /**
   * Returns a copy of 'key' but with all non-ASCII characters removed
   */
  public static normalize(key: string) {
    return key
      .normalize("NFD") // Replace accented characters with non-accented equivalent
      .replace(/[^\x00-\x7F]/g, "") // Remove non-ASCII characters
      .replace(/[^0-9a-zA-Z]/g, "_"); // Replace punctuation with underscore
  }

  /**
   * Returns the value of the specified environment variable
   * @param name
   * @throws an assertion error if it is not defined
   */
  public static getEnv(name: string) {
    const value = process.env[name];
    if (!value) throw new Error(`Environment variable ${name} is missing`);
    return value;
  }

  /**
   * Given a set of email delivery statuses, this function will return the most "meaningful" status
   */
  public static computeDeliveryStatusFromSet(
    allStatuses: Set<EmailDeliveryStatus | undefined>,
  ): EmailDeliveryStatus | undefined {
    if (allStatuses.has("DELIVERY")) return "DELIVERY";
    if (allStatuses.has("BOUNCE_PERMANENT")) return "BOUNCE_PERMANENT";
    if (allStatuses.has("BOUNCE_TRANSIENT")) return "BOUNCE_TRANSIENT";
    if (allStatuses.has("DISPATCHED")) return "DISPATCHED";
    return undefined;
  }

  /**
   * Returns the spreadsheet column definition for 'columnName'
   * TODO: Optimise this by converting it to a dictionary first
   */
  public static getColumnDef(respondentDefinition: RespondentDefinition, attributeName: string) {
    for (const columnDef of respondentDefinition.attributes) {
      if (columnDef.attributeName === attributeName) return columnDef;
    }

    return undefined;
  }
}
