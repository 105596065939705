export type RoleName =
	"ROLE_DEFAULT" |
	"ROLE_EDIT_SURVEY_PROJECT" |
	"ROLE_EDIT_SURVEY_COHORT" |
	"ROLE_ADMIN" |
	"ROLE_SUPER_ADMIN" |
	"ROLE_ADD_RESPONDENT" |
	"ROLE_SEND_EMAILS" |
	"ROLE_UPLOAD_SPREADSHEET";

export type AppRole = {
	id: RoleName,
	endpoints: string[],
	description: string,
	name: string,
};

export const AppRoles = new Map<RoleName, AppRole>([
	["ROLE_DEFAULT", {
		id: "ROLE_DEFAULT",
		description: "Default role which all users implicitly possess",
		name: "Default",
		endpoints: [
			"/GET/profile",
			"/GET/legacy-embed-url", // TODO: !!! This is only for the Nobia instance
			"/GET/survey-projects",
			"/GET/survey-projects/*",
			"/GET/survey-projects/*/survey-cohorts",
			"/GET/survey-instances/*",
			"/GET/respondent-search/*",
		],
	}],

	["ROLE_UPLOAD_SPREADSHEET", {
		id: "ROLE_UPLOAD_SPREADSHEET",
		description: "Allows the user to add Respondents by uploading a spreadsheet; grants access to the upload history",
		name: "Upload spreadsheet",
		endpoints: [
			"/POST/survey-projects/*/import-respondents",
			"/GET/survey-projects/*/respondent-imports",
			"/GET/survey-projects/*/respondent-import-batches/*",
			"/DELETE/survey-instances/*",
		],
	}],

	["ROLE_ADD_RESPONDENT", {
		id: "ROLE_ADD_RESPONDENT",
		description: "Allows the user to add an individual respondent",
		name: "Add respondent",
		endpoints: [
			"/POST/survey-projects/*/import-respondents",
			"/DELETE/survey-instances/*",
		],
	}],

	["ROLE_EDIT_SURVEY_PROJECT", {
		id: "ROLE_EDIT_SURVEY_PROJECT",
		description: "Allows the user to create and edit Projects",
		name: "Edit Projects",
		endpoints: [
			"/POST/survey-projects",
			"/PUT/survey-projects/*",
			"/DELETE/survey-projects/*",
		],
	}],

	["ROLE_EDIT_SURVEY_COHORT", {
		id: "ROLE_EDIT_SURVEY_COHORT",
		description: "Allows the user to create and edit Cohorts",
		name: "Edit Cohorts",
		endpoints: [
			"/POST/survey-projects/*/survey-cohorts",
			"/PUT/survey-projects/*/survey-cohorts/*",
			"/DELETE/survey-cohorts/*",
		],
	}],

	["ROLE_SEND_EMAILS", {
		id: "ROLE_SEND_EMAILS",
		description: "Allows the user to resend invite emails, or pre-empt scheduled emails",
		name: "Send emails",
		endpoints: [
			"/POST/survey-projects/*/send-survey-emails",
			"/POST/survey-instances/*/resend-invite-email",
		],
	}],

	["ROLE_ADMIN", {
		id: "ROLE_ADMIN",
		description: "Allows the user to create, edit and delete other users",
		name: "Administrator",
		endpoints: [
			"/POST/users",
			"/GET/users/*",
			"/GET/users",
			"/PUT/users/*",
			"/DELETE/users/*",
			"/POST/users/*/reset-password",
		],
	}],
]);
