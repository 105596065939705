import React from "react";
import { SurveyApp, SurveyHeader, SurveyProvider, ValidationProvider } from "@careerinsight/applib-react";

function App() {
  return (
    <SurveyProvider>
      <ValidationProvider>
        <SurveyApp>
          <SurveyHeader />
        </SurveyApp>
      </ValidationProvider>
    </SurveyProvider>
  );
}

export default App;
